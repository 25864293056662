<template>
  <!-- ==== hero section start ==== -->
  <section
    class="hero bg__img"
    :class="[
      page == 'faq' ? 'hero--secondary hero--tertiary hero--quaternary' : '',
      page == 'services' ? 'hero--secondary' : '',
      page == 'concierge' ? 'hero--quinary' : '',
      page == 'home' ? 'hero__one__alt' : '',

    ]"
    :style="{
      backgroundImage: 'url(' + bgImg + ')',
    }"
  >
    <div class="container-fluid">
      <div class="hero__content">
        <carousel :items="1" :autoplay="true" :nav="false" class="hero__slider">
          <template v-if="page == 'concierge'">
            <div class="slider__content text-start"  v-for="(content, index) in 3"
              :key="index">
              <h1
                class="
                  text-start
                  cta__space
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-duration="0.8s"
              >
                All-Inclusive pricing in a single checkout - purchase on your
                behalf.
              </h1>
              <h3 class="cta__space wow animate__animated animate__fadeInUp">
                No U.S credi card or U.S address required.
              </h3>
              <p
                class="
                  secondary
                  cta__space
                  text-start
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.1s"
                data-wow-duration="0.8s"
              >
                No more excess baggage cost. No more wondering how to get
                products thats based in india only.
              </p>
              <h3 class="section__header__space">Buy - for - me service</h3>
              <a href="index.html" class="button primary"
                >Sign Up & Purchase with ShipIndia</a
              >
            </div>
          </template>
          <template v-else>
            <div
              class="slider__content"
              v-for="(content, index) in contents"
              :key="index"
            >
              <h1
                class="
                  text-center
                  cta__space
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                {{ content.heading }}
              </h1>
              <p
                class="
                  secondary
                  text-center
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                {{ content.text }}
              </p>
            </div>
          </template>
        </carousel>
      </div>
    </div>
  </section>
  <!-- ==== #hero section end ==== -->
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  props: ["page", "contents"],
  name: "HomeCarousal",
  components: {
    carousel,
  },
  data() {
    return {};
  },
  computed: {
    bgImg() {
      if (this.page == "faq") {
        return require("../assets/images/hero/four.png");
      } else if (this.page == "services") {
        return require("../assets/images/hero/two.png");
      } else if (this.page == "concierge") {
        return require("../assets/images/hero/five.png");
      } else {
        return require("../assets/images/hero/one.png");
      }
    },
  },
  methods: {},
};
</script>

<style>
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}
</style>
