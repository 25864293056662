var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero bg__img",class:[
    _vm.page == 'faq' ? 'hero--secondary hero--tertiary hero--quaternary' : '',
    _vm.page == 'services' ? 'hero--secondary' : '',
    _vm.page == 'concierge' ? 'hero--quinary' : '',
    _vm.page == 'home' ? 'hero__one__alt' : '',

  ],style:({
    backgroundImage: 'url(' + _vm.bgImg + ')',
  })},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"hero__content"},[_c('carousel',{staticClass:"hero__slider",attrs:{"items":1,"autoplay":true,"nav":false}},[(_vm.page == 'concierge')?_vm._l((3),function(content,index){return _c('div',{key:index,staticClass:"slider__content text-start"},[_c('h1',{staticClass:"text-start cta__space wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"0.8s"}},[_vm._v(" All-Inclusive pricing in a single checkout - purchase on your behalf. ")]),_c('h3',{staticClass:"cta__space wow animate__animated animate__fadeInUp"},[_vm._v(" No U.S credi card or U.S address required. ")]),_c('p',{staticClass:"secondary cta__space text-start wow animate__animated animate__fadeInUp",attrs:{"data-wow-delay":"0.1s","data-wow-duration":"0.8s"}},[_vm._v(" No more excess baggage cost. No more wondering how to get products thats based in india only. ")]),_c('h3',{staticClass:"section__header__space"},[_vm._v("Buy - for - me service")]),_c('a',{staticClass:"button primary",attrs:{"href":"index.html"}},[_vm._v("Sign Up & Purchase with ShipIndia")])])}):_vm._l((_vm.contents),function(content,index){return _c('div',{key:index,staticClass:"slider__content"},[_c('h1',{staticClass:"text-center cta__space wow animate__animated animate__fadeInUp"},[_vm._v(" "+_vm._s(content.heading)+" ")]),_c('p',{staticClass:"secondary text-center wow animate__animated animate__fadeInUp"},[_vm._v(" "+_vm._s(content.text)+" ")])])})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }